import React, { Component } from 'react';
//import DatePicker from "react-datepicker";
import { postData, handleChangeSelectDatepicker,handleDateChangeRaw } from '../../service/common.js';
import BlockUi from 'react-block-ui';
import "react-datepicker/dist/react-datepicker.css";
import { LOGIN_SVG, OCS_LOGO} from '../../service/OcsConstant.js';
import moment from 'moment';
import DateOfBirthDatePicker, { defaultDateFormat as defaultDateOfBirthDateFormat } from './DateOfBirthDatePicker';

class OnlineAssessment extends Component {
    
    constructor(props) {
        super(props);
  
        this.state = { 
            uuid: '', 
            time: {}, 
            seconds: '', 
            assessmentStatus: '', 
            dob:'', 
            dob_validated: false, 
            to_date: '', 
            to_date_error: false,
            full_name: ''
        };
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
          
        this.handleChange = this.handleChange.bind(this);
        //this.onSubmit = this.onSubmit.bind(this);
    }
    
    secondsToTime(secs){

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
        if(minutes < 10) minutes = '0'+minutes;

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
        if(seconds < 10) seconds = '0'+seconds;

        let obj = {
          "m": minutes,
          "s": seconds
        };
        return obj;
    }
    
    componentDidMount() {
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });
        this.setState({
            uuid: this.props.match.params.uuid
        });
        this.fetchDataByUUID(this.props.match.params.uuid);
    }
    
    
    fetchDataByUUID(uuid){
        
        this.setState({loading: true, error: ''});

        postData('admin/Login/online_assessment',{uuid: uuid}).then((result) => {
            this.setState({loading: false});
            
            if (result.status) {
                if(result.data[0] != undefined) {
                    this.setState({seconds: result.data[0].duration, dob : result.data[0].date_of_birth, 
                                   assessmentStatus: result.data[0].status, full_name: result.data[0].full_name});
                }
            } else {
                    this.setState({error: <span><i className="icon ocs-warning2-ie"></i><div>{result.error}</div></span>});
            }
            
            this.setState({loading: false});
        });
        
    }

    startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
          time: this.secondsToTime(seconds),
          seconds: seconds,
        });
        
        // Check if we're at zero.
        if (seconds == 0) { 
            clearInterval(this.timer);
            //link expire
            this.setState({
                linkExpired: 1,
                assessmentStatus: 3
            });

            postData('admin/Login/change_assessment_status',{uuid: this.state.uuid, status: 3}).then((result) => {
                this.setState({loading: false});
            });
           
        }
    }

    handleChange(e) {
        this.setState({email: e.target.value});
        this.setState({error: ''});
        this.setState({success: ''});
    }

    onSubmit(e) {
        e.preventDefault();
    }
    
    validateDOB(){

        let userDob = moment(this.state.to_date).format("YYYY-DD-MM");
        if(userDob == this.state.dob){
            this.setState({dob_validated: true});
            this.startTimer();
        } else {
            this.setState({to_date_error: true});    
        }
    }
    
    validateCancel(){
        window.open("about:blank", "_self");
        window.close();
    }

    renderDateOfBirthDatePicker() {
        let selected = this.state.to_date

        let dateOfBirthMoment = moment(selected, defaultDateOfBirthDateFormat)
        if (!dateOfBirthMoment.isValid()) {
            selected = null
        }

        return (
            <DateOfBirthDatePicker
                title={selected ? dateOfBirthMoment.format('dddd, DD MMMM YYYY') : undefined}
                onChange={dateMoment => this.setState({ to_date: dateMoment ? dateMoment.format(defaultDateOfBirthDateFormat) : null })}
                name="to_date"
                selected={selected ? dateOfBirthMoment : null}
            />
        )
    }

    render() {
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.loading}>
                    <section className="gradient_color">
                    {(this.state.assessmentStatus == 1) ?
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                     <div className="logo text-center"><img alt="logo" className="img-fluid" width="70px" src={OCS_LOGO}/></div>
                                </div> 
                                {(this.state.dob_validated) ? 
                                <div className="col-md-4 col-md-offset-4">
                                    <h1>{this.state.time.m} : {this.state.time.s} </h1>
                                </div> : ''}
                            </div>
                            {(!this.state.dob_validated) ?
                            <div>
                                <div className="row">
                                    <div className="col-md-12 text-center P_30_T">
                                         <h1>ONCALL - SKILLS & KNOWLEDGE ASSESSMENT </h1> 
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 text-center P_30_T">
                                         <h6>Confirm your identity by providing your registered Date of Birth below</h6> 
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 text-center P_30_T">
                                        
                                        {this.renderDateOfBirthDatePicker()}
                                    </div>
                                    {(this.state.to_date_error) ?
                                    <div className="col-lg-12 col-md-12 text-center P_30_T">
                                        <h6>Incorrect Date of Birth </h6>
                                    </div> : '' }
                                </div>
                                
                                <div className='col-lg-12 col-md-12 text-center P_30_T'>
                                    <input type='button' className='btn cmn-btn1 crte_svBtn' value='Cancel' onClick={(e) => this.validateCancel()} />
                                    <input type='button' className='btn cmn-btn1 crte_svBtn' value='Submit' onClick={(e) => this.validateDOB()} />
                                </div>
                            </div>
                            : 
                                <div className="row">
                                    <div className="col-md-12 text-center P_30_T">
                                         <h1>ONCALL - SKILLS & KNOWLEDGE ASSESSMENT </h1> 
                                    </div>
                                    <div className="col-md-12 text-center P_30_T">
                                         <h6>{this.state.full_name} </h6> 
                                    </div>
                                    
                                </div>
                            }
                        </div> : ''}
                        
                        {(this.state.assessmentStatus == 5) ?
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">
                                         <div className="logo text-center"><img alt="logo" className="img-fluid" width="70px" src={OCS_LOGO}/></div>
                                    </div> 
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-12 text-center P_30_T">
                                         <h1>ONCALL - SKILLS & KNOWLEDGE ASSESSMENT </h1> 
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 text-center P_30_T">
                                        <h6>Your link has been Expired </h6>
                                    </div>
                                </div>
                            </div>                        
                        : ''}
                        
                        {(this.state.assessmentStatus == 3) ?
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">
                                         <div className="logo text-center"><img alt="logo" className="img-fluid" width="70px" src={OCS_LOGO}/></div>
                                    </div> 
                                </div>
                                
                                <div className="row">
                                    <div className="col-md-12 text-center P_30_T">
                                         <h1>ONCALL - SKILLS & KNOWLEDGE ASSESSMENT </h1> 
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 text-center P_30_T">
                                        <h6>Your assessment has been completed </h6>
                                    </div>
                                </div>
                            </div>                        
                        : ''}
                    </section>

                    <footer className="text-center">
                        <div className="container">
                            <div className="row">
                                <h6>© {moment().format("YYYY")} All Rights Reserved <span>Healthcare Manager</span></h6>
                            </div>
                        </div>
                    </footer>

                </BlockUi>
            </div>
        )

    }    
}

export default OnlineAssessment;

